<template>
  <div class="d-flex flex-column p-3 msg-display"
       v-bind:class="{'text-primary msg-sent': !source.incoming, 'text-info': source.incoming}"
       :key="source.id">
    <div class="msg-body" v-bind:class="{'d-flex justify-content-end': !source.incoming}" v-if="source.mesg.trim().length > 0">
      <vue-markdown :source="source.mesg"></vue-markdown>
    </div>
    <b-img-lazy fluid v-if="isImage()" :src="attachmentUrl()" style="max-width: 300px"></b-img-lazy>

    <l-map
        v-if="isLocationMessage()"
        ref="map"
        style="height: 200px; width: 200px;"
        :zoom="zoom"
        :center="center(m)">
      <l-tile-layer :url="url"></l-tile-layer>
      <l-marker :lat-lng="center()"></l-marker>
    </l-map>

    <div class="d-flex mt-3" v-bind:class="{'justify-content-end': !source.incoming}">
     <div class="d-flex justify-content-center align-items-center align-content-center">
       <a :href="attachmentUrl()" v-if="isPDF()" target="_blank" class="btn btn-outline-info">
         <font-awesome-icon :icon="['far','file-pdf']"></font-awesome-icon>
       </a>
       <a :href="locationUrl()" v-if="isLocationMessage()" target="_blank" class="btn btn-outline-primary">
         <font-awesome-icon :icon="['far','location']"></font-awesome-icon>
       </a>

       <div class="">
         <div class="btn btn-outline-secondary" v-bind:class="{'text-right': !source.incoming}">
           {{ humanize() }}
           <span v-if="source.read || !source.incoming"><font-awesome-icon :icon="['far', 'check-double']"></font-awesome-icon></span>
         </div>
       </div>

       <div class="btn">
         <display-brand v-if="source.brand" :id="source.brand.id" :width="24"></display-brand>
       </div>
     </div>
    </div>

  </div>
</template>
<script>
import parseISO from 'date-fns/parseISO'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { LMap, LMarker, LTileLayer } from 'vue2-leaflet'
import VueMarkdown from 'vue-markdown'
import DisplayBrand from "@/components/display-brand";

export default {
  name: 'SingleMessageDisplay',
  props: {
    source: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  components: {
    DisplayBrand,
    LMap,
    LTileLayer,
    LMarker,
    VueMarkdown,
  },
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 16,
    }
  },
  methods: {

    humanize() {
      let at = this.source.at
      if (!at) return ''
      try {
        let iso = parseISO(at)
        return formatDistanceToNow(iso)
      }catch (e){
        console.log('error in formatting time', at)
        return ''
      }
    },
    isImage() {
      let m = this.source
      return m.attachments && m.attachments.length > 0 && m.attachments[0].indexOf('.pdf') === -1 && m.attachments[0].indexOf('.mp4') === -1
    },
    isPDF() {
      let m = this.source
      return m.attachments && m.attachments.length > 0 && m.attachments[0].indexOf('.pdf') > -1
    },
    attachmentUrl() {
      let m = this.source
      if (!m.attachments) return null
      if (m.attachments.length === 0) return null
      let f = m.attachments[0]
      if(f.startsWith("http")) {
        return f
      }
      return `${process.env.VUE_APP_URL}/public/attachment?file=${f}`
    },

    isLocationMessage() {
      let m = this.source
      return (m.location && m.location.lat > 0 && m.location.lng > 0)
    },
    locationUrl() {
      let m = this.source
      if (m.location && m.location.lat > 0 && m.location.lng > 0) {
        return `https://www.google.com/maps/search/?api=1&query=${m.location.lat},${m.location.lng}`
      } else {
        return null
      }
    },
    center() {
      let m = this.source
      if (!m.location) return [0,0]
      return [m.location.lat, m.location.lng]
    }
  }
}
</script>