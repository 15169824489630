<template>
  <div class="d-flex flex-column">
    <div class="list-group bg-transparent">
      <div class="list-group-item d-flex flex-column bg-transparent">

        <b-form-textarea v-model="mesg" placeholder="New Message"></b-form-textarea>

        <b-form-file v-model="files" multiple placeholder="Select Files to Send" class="mt-3"></b-form-file>

        <div class="d-flex mt-3">
          <div class="flex-fill flex-grow-1">&nbsp;</div>

          <div>
            <b-form-select v-model="brandId"
                           :options="$store.state.brands"
                           text-field="name" value-field="id"></b-form-select>
          </div>



          <div class="ml-3">
            <b-button @click="sendMessageLocal" class="" :disabled="!brandId" variant="primary">
              <font-awesome-icon icon="check"></font-awesome-icon>
              Send
            </b-button>
          </div>
        </div>
      </div>

      <div class="list-group-item d-flex justify-content-end text-end my-3">
        <b-form-group label="Filter Messages">
          <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="filters"
              :options="options"
              name="filters-1"
              switches
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <virtual-list style="height: 80vh; overflow-y: auto;width: 100%"
                  wrap-class="d-flex flex-column"
                  item-class="listitem bb-1"
                  :data-key="'id'"
                  :data-sources="filteredMessages"
                  :data-component="itemComponent"
                  :estimate-size="60">
    </virtual-list>
  </div>
</template>
<script>
import axios from 'axios'
import VirtualList from 'vue-virtual-scroll-list'
import SingleMessageDisplay from './single-message-display'

export default {
  name: 'MessageDisplay',
  props: ['messages', 'customerId'],
  components: {
    VirtualList,
  },
  data: () => ({
    filters: ['RECEIVED', 'SENT'],
    options: ['RECEIVED', 'SENT', 'LOCATION'],
    mesg: '',
    brandId: null,
    files: [],
    itemComponent: SingleMessageDisplay,
  }),
  watch: {
    customerId(){
      this.filters = ['RECEIVED', 'SENT']
      this.brandId = (this.messages.length > 0 ? this.messages[0].brand.id : null)
    }
  },
  computed: {
    filteredMessages(){
      let m = this.messages
      if(this.filters.indexOf('RECEIVED') === -1){
        //not asking for received, i.e only sent
        m = m.filter(m0 => !m0.incoming)
      }
      if(this.filters.indexOf('SENT') === -1){
        //not asking for sent, i.e only incoming
        m = m.filter(m0 => m0.incoming)
      }
      if(this.filters.indexOf('LOCATION') > -1){
        //show only location
        m = this.messages.filter(m0 => m0.incoming && m0.location && m0.location.lat > 0 && m0.location.lng > 0)
      }
      return m
    }
  },
  methods: {

    async sendMessageLocal () {
      let fd = new FormData()
      fd.append('customerId', this.customerId)
      fd.append('mesg', this.mesg)
      fd.append('brandId', this.brandId)
      this.files.forEach(f => {
        fd.append('files', f, f.name)
      })
      await axios({
        method: 'post',
        url: '/api/admin/message/attachment',
        data: fd,
        headers: {'Content-Type': 'multipart/form-data'}
      })
      this.mesg = ''
      await this.$emit('message-updated')
    },
  },
  async created () {
    await this.$store.dispatch('loadBrands')
    if(this.messages){
      this.brandId = (this.messages.length > 0 ? this.messages[0].brand.id : null)
    }
  }
}
</script>